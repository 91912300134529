<template>
    <el-form :model="classForm" ref="classForm" label-width="100px">
        <div v-for="(item, index) in classDataForm" :key="index">
            <el-form-item :label="item.label" v-if="item.isInput != false" :rules="item.rules" :prop="item.attr">
                <uploaderimgfile v-if="item.type == 'img'" v-model="classForm[item.attr]" :imageUrl="imageUrl" @handleAvatarSuccess="handleAvatarSuccess" />
                <el-select v-else-if="item.type == 'enum'" v-model="classForm[item.attr]" :placeholder="item.placeholder" filterable>
                    <el-option v-for="ite in item.dic" :key="ite.value" :label="ite.title" :value="ite.value">
                    </el-option>
                </el-select>
                <el-date-picker v-else-if="item.type == 'date'" type="date" :placeholder="item.placeholder" v-model="classForm[item.attr]" :format="item.format" :value-format="item.valueformat"></el-date-picker>
                <el-input v-else-if="item.type == 'textarea'" type="textarea" v-model="classForm[item.attr]" :placeholder="item.placeholder"></el-input>
                <el-input v-else v-model="classForm[item.attr]" :placeholder="item.placeholder"></el-input>
            </el-form-item>
        </div>
        <el-form-item>
            <el-button type="primary" @click="submitForm('classForm')">保存</el-button>
            <el-button @click="resetForm('classForm')">重置</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: "saveClass",
    props: {
        classid: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            imageUrl: {
                Id: 0,
                Url: "",
            },
            classForm: {
                Id: 0,
                ClassName: "", //班级名称
                ClassType: "", //学习阶段
                Date: "", //开学日期
                ClassIcon: "",
                RemarkInfo: "",
            },
            classDataForm: [
                {
                    label: "Id",
                    attr: "Id",
                    isInput: false,
                },
                {
                    label: "班级Icon",
                    attr: "ClassIcon",
                    rules: [{ required: true, message: "请上传图标" }],
                    type: "img",
                },
                {
                    label: "开学日期",
                    attr: "Date",
                    placeholder: "选择开学日期",
                    format: "YYYY 年 MM 月 DD 日",
                    valueformat: "YYYY/MM/DD",
                    rules: [
                        {
                            type: "date",
                            required: true,
                            message: "请选择开学日期",
                            trigger: "change",
                        },
                    ],
                    type: "date",
                },
                {
                    label: "学习阶段",
                    attr: "ClassType",
                    placeholder: "请选择学习阶段",
                    rules: [
                        {
                            required: true,
                            message: "请选择学习阶段",
                            trigger: "change",
                        },
                    ],
                    type: "enum",
                    dic: [
                        { value: 0, title: "幼儿园" },
                        { value: 1, title: "小学" },
                        { value: 2, title: "初中" },
                        { value: 3, title: "高中" },
                        { value: 4, title: "大学" },
                    ],
                    formatters: function (val) {
                        var result;
                        switch (val) {
                            case 0:
                                result = "幼儿园";
                                break;
                            case 1:
                                result = "小学";
                                break;
                            case 2:
                                result = "初中";
                                break;
                            case 3:
                                result = "高中";
                                break;
                            case 4:
                                result = "大学";
                                break;
                            default:
                                result = "未知";
                                break;
                        }
                        return result;
                    },
                },
                {
                    label: "班级名称",
                    attr: "ClassName",
                    placeholder:
                        "格式：阶段(幼,小,初,高,大)+****级+**班；例：小2021级01班",
                    rules: [
                        {
                            required: true,
                            trigger: "blur",
                            message: "请输入班级名称",
                        },
                        {
                            pattern:
                                /^[\u5e7c,\u5c0f,\u521d,\u9ad8,\u5927]\d{4}[\u7ea7]\d{2}[\u73ed]$/,
                            message: "名称格式有误！例：小2021级01班",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "备注",
                    attr: "RemarkInfo",
                    placeholder: "请输入备注（长度不大于100个字符）",
                    rules: [
                        {
                            min: 0,
                            max: 100,
                            message: "长度不大于100个字符",
                            trigger: "blur",
                        },
                    ],
                    type: "textarea",
                },
            ],
        };
    },
    created() {
        var that = this;
        if (that.$props.classid != 0) {
            that.$API.Classes.QueryByClassId.get(that.$props.classid).then(
                (res) => {
                    console.log(res);
                    if (res) {
                        that.imageUrl.Id = res.ClassIcon;
                        that.imageUrl.Url = res.ClassIconUrl;
                        that.classForm.Id = res.Id;
                        that.classForm.ClassName = res.ClassName;
                        that.classForm.ClassType = res.ClassType;
                        that.classForm.Date = res.Date;
                        that.classForm.ClassIcon = res.ClassIcon;
                        that.classForm.RemarkInfo = res.RemarkInfo;
                    }
                }
            );
        }
    },
    methods: {
        handleAvatarSuccess(res) {
            if (res.Success && res.Code == 200) {
                this.imageUrl = res.Data;
                this.classForm.ClassIcon = this.imageUrl.Id;
            } else {
                console.log(res.Message);
            }
        },
        submitForm(formName) {
            var that = this;
            that.$refs[formName].validate((valid) => {
                if (valid) {
                    that.$API.Classes.AddOrUpdate.post(that.classForm).then(
                        (res) => {
                            console.log(res);
                            if (res.Success) {
                                that.$message.success(res.Message);
                                that.$emit("classSaveSuccess");
                            }
                        }
                    );
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.imageUrl = {};
            this.$refs[formName].resetFields();
        },
    },
};
</script>

<style>
</style>