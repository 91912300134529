<template>
  <el-form :model="classForm" ref="classForm" label-width="100px">
    <div v-for="(item, index) in classDataForm" :key="index">
      <el-form-item
        :label="item.label"
        v-if="item.isInput != false"
        :rules="item.rules"
        :prop="item.attr"
      >
        <uploaderimgfile
          v-if="item.type == 'img'"
          v-model="classForm[item.attr]"
          :imageUrl="imageUrl"
          @handleAvatarSuccess="handleAvatarSuccess"
        />
        <el-select
          v-else-if="item.type == 'enum'"
          v-model="classForm[item.attr]"
          :placeholder="item.placeholder"
          filterable
        >
          <el-option
            v-for="ite in item.dic"
            :key="ite.value"
            :label="ite.title"
            :value="ite.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-else-if="item.type == 'date'"
          type="date"
          :placeholder="item.placeholder"
          v-model="classForm[item.attr]"
          :format="item.format"
          :value-format="item.valueformat"
        ></el-date-picker>
        <el-input-number
          v-else-if="item.type == 'number'"
          v-model="classForm[item.attr]"
          :min="item.min"
          :max="item.max"
        ></el-input-number>
        <el-input
          v-else-if="item.type == 'textarea'"
          type="textarea"
          v-model="classForm[item.attr]"
          :placeholder="item.placeholder"
        ></el-input>
        <el-input
          v-else
          v-model="classForm[item.attr]"
          :placeholder="item.placeholder"
        ></el-input>
      </el-form-item>
    </div>
    <div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('classForm')"
          >保存</el-button
        >
        <el-button @click="resetForm('classForm')">重置</el-button>
      </el-form-item>
    </div>
  </el-form>
</template>
<script>
import { ElMessage } from "element-plus";
export default {
  name: "classAddBatch",
  data() {
    return {
      imageUrl: {
        Id: 190016502280262,
        Url: "",
      },
      classForm: {
        ClassType: "", //学习阶段
        Date: "", //开学日期
        ClassIcon: "",
        Number: 0,
      },
      classDataForm: [
        {
          label: "班级Icon",
          attr: "ClassIcon",
          rules: [{ required: true, message: "请上传图标" }],
          type: "img",
        },
        {
          label: "开学日期",
          attr: "Date",
          placeholder: "选择开学日期",
          format: "YYYY 年 MM 月 DD 日",
          valueformat: "YYYY/MM/DD",
          rules: [
            {
              type: "date",
              required: true,
              message: "请选择开学日期",
              trigger: "change",
            },
          ],
          type: "date",
        },
        {
          label: "学习阶段",
          attr: "ClassType",
          placeholder: "请选择学习阶段",
          rules: [
            { required: true, message: "请选择学习阶段", trigger: "change" },
          ],
          type: "enum",
          dic: [
            { value: 0, title: "幼儿园" },
            { value: 1, title: "小学" },
            { value: 2, title: "初中" },
            { value: 3, title: "高中" },
            { value: 4, title: "大学" },
          ],
        },
        {
          label: "班级数量",
          attr: "Number",
          rules: [{ required: true, message: "请输入班级数量" }],
          type: "number",
          min: 1,
          max: 30,
        },
      ],
    };
  },
  methods: {
    handleAvatarSuccess(res) {
      if (res.Success && res.Code == 200) {
        this.imageUrl = res.Data;
        this.classForm.ClassIcon = this.imageUrl.Id;
      } else {
        console.log(res.Message);
      }
    },
    submitForm(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.$API.Classes.ClassAddBatch.post(that.classForm).then((res) => {
            console.log(res);
            if (res.Success) {
              that.$message.success(res.Message);
              that.$emit("addBatchSuccess");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.imageUrl = {};
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style>
</style>