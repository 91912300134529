<template>
  <div class="main">
    <el-scrollbar max-height="750px">
      <el-empty v-if="ctsData.length <= 0" description="暂无任课信息"></el-empty>
      <div v-else v-for="(item, index) in ctsData" :key="index">
        <el-descriptions :title="`任课` + (index + 1)" :column="2" :size="size" border>
          <template #extra>
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="small"
              @click="editCts(item.Id)"
              >编辑</el-button
            >
            <el-popconfirm
              confirmButtonText="确定"
              cancelButtonText="取消"
              icon="el-icon-info"
              iconColor="red"
              title="确定删除吗？"
              @confirm="ctsDelete(item.Id)"
            >
              <template #reference>
                <el-button type="danger" icon="el-icon-delete" size="small"
                  >删除</el-button
                >
              </template>
            </el-popconfirm>
          </template>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-user"></i>
              姓名
            </template>
            {{ item.AdminName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-connection"></i>
              科目
            </template>
            <el-tag size="small">{{ item.SubjectName }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-mobile-phone"></i>
              电话
            </template>
            {{ item.AdminPhoneAccount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-tickets"></i>
              是否是班主任
            </template>
            <el-tag size="small"> {{ item.IsHeadmaster ? "是" : "不是" }}</el-tag>
          </el-descriptions-item>
        </el-descriptions>
        <el-divider />
      </div>
    </el-scrollbar>
    <el-divider>
      <el-button type="primary" icon="el-icon-plus" @click="addCts"
        >添加任课信息</el-button
      ></el-divider
    >
    <el-dialog :title="ctsDialogTitle" v-model="ctsDialogVisible" width="30%" center>
      <el-form :model="ctsForm" ref="ctsForm" label-width="100px">
        <div v-for="(item, index) in ctsFormConfig" :key="index">
          <el-form-item
            :label="item.label"
            v-if="item.isInput != false"
            :rules="item.rules"
            :prop="item.attr"
          >
            <el-select
              v-if="item.type == 'enum'"
              v-model="ctsForm[item.attr]"
              :placeholder="item.placeholder"
              filterable
            >
              <el-option
                v-for="ite in item.dic"
                :key="ite.value"
                :label="ite.title"
                :value="ite.value"
              >
              </el-option>
            </el-select>
            <el-switch
              v-else-if="item.type == 'switch'"
              v-model="ctsForm[item.attr]"
            ></el-switch>
            <el-input
              v-else-if="item.type == 'textarea'"
              type="textarea"
              v-model="ctsForm[item.attr]"
              :placeholder="item.placeholder"
            ></el-input>
            <el-input
              v-else
              v-model="ctsForm[item.attr]"
              :placeholder="item.placeholder"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ctsDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveCts('ctsForm')">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "classTeacherSubject",
  props: {
    classId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ctsData: [],
      ctsDialogTitle: "",
      ctsDialogVisible: false,
      ctsForm: {
        Id: 0,
        ClassId: 0,
        AdminID: 0,
        SubjectID: 0,
        IsHeadmaster: false,
        RemarkInfo: "",
      },
      ctsFormConfig: [
        {
          label: "任课教师",
          attr: "AdminID",
          placeholder: "请选择任课教师",
          rules: [{ required: true, message: "请选择任课教师!", trigger: "change" }],
          type: "enum",
          dic: this.GetWorkerList(),
        },
        {
          label: "任课科目",
          attr: "SubjectID",
          placeholder: "请选择任课科目",
          rules: [{ required: true, message: "请选择任课科目!", trigger: "change" }],
          type: "enum",
          dic: this.GetSchoolSubjects(),
        },
        { label: "是班主任", attr: "IsHeadmaster", type: "switch" },
        {
          label: "备注",
          attr: "RemarkInfo",
          placeholder: "请输入备注",
          type: "textarea",
          rules: [{ max: 200, message: "长度不大于200!" }],
        },
      ],
    };
  },
  mounted() {
    this.initClassTeacherSubjects();
  },
  methods: {
    initClassTeacherSubjects() {
      var that = this;
      if (that.$props.classId != 0) {
        that.$API.ClassTeacherSubject.GetClassTeacherSubjects.get(
          that.$props.classId
        ).then((res) => {
          that.ctsData = res;
        });
      }
    },
    GetWorkerList() {
      var data = [];
      this.$API.ClassTeacherSubject.GetWorkerList.get().then((res) => {
        if (res) {
          res.forEach((it) => {
            data.push({
              value: it.WorkerId,
              title: `${it.WorkerName}(${it.WorkerPhone})`,
            });
          });
        }
      });
      return data;
    },
    GetSchoolSubjects() {
      var data = [];
      this.$API.SchoolSubject.GetSchoolSubjects.get().then((res) => {
        if (res) {
          res.forEach((it) => {
            data.push({ value: it.Id, title: it.SubjectName });
          });
        }
      });
      return data;
    },
    ctsDelete(id) {
      var that = this;
      that.$API.ClassTeacherSubject.FakeDelete.delete([id]).then((res) => {
        if (res.Success) {
          that.$message.success(res.Message);
          that.initClassTeacherSubjects();
        }
      });
    },
    addCts() {
      var that = this;
      that.ctsDialogVisible = true;
      that.ctsDialogTitle = "添加任课";
      that.ctsForm = {
        Id: 0,
        ClassId: that.$props.classId,
        AdminID: "",
        SubjectID: "",
        IsHeadmaster: false,
        RemarkInfo: "",
      };
    },
    editCts(id) {
      var that = this;
      var data = that.ctsData.filter(
        (it) => it.Id == id && it.ClassId == that.$props.classId
      );
      that.ctsForm.Id = data[0].Id;
      that.ctsForm.ClassId = that.$props.classId;
      that.ctsForm.AdminID = data[0].AdminID;
      that.ctsForm.SubjectID = data[0].SubjectID;
      that.ctsForm.IsHeadmaster = data[0].IsHeadmaster;
      that.ctsForm.RemarkInfo = data[0].RemarkInfo;
      that.ctsDialogVisible = true;
      that.ctsDialogTitle = "编辑任课信息";
    },
    saveCts(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.$API.ClassTeacherSubject.AddOrUpdate.post(that.ctsForm).then((res) => {
            that.ctsDialogVisible = false;
            if (res.Success) {
              that.$message.success(res.Message);
              that.initClassTeacherSubjects();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style>
.main {
  margin: 20px;
}
</style>
